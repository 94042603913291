<template>
  <div>
    <div v-if="!showDialog" class="page padding-top15">
      <div v-if="!showDialog" class="border-radius4 height50" style="background: #FAFAFA;display: flex;">
        <div class="width110 font-size16 border-radius4004"
          style="background: #0768FD;color: white;display: flex; align-items: center;justify-content: center;cursor: pointer;"
          @click="go2Back">
          返回
        </div>
        <div style="color: black;font-weight: bold;display: flex; align-items: center;"
          class="font-size16 margin-left30">
          {{ parkingName }}
        </div>
      </div>
      <div v-if="!showDialog" v-loading="isLoading" class="content-box" style="padding-top: 0;background: #F4F7FC;">
        <!--      <div class="search-box page-header" style="margin-bottom: 1px;padding: 10px 38px;">-->
        <!--        <el-form ref="searchForm" :inline="true" :model="query" size="medium">-->
        <!--          <el-form-item prop="parkingLotId" style="margin-bottom: 0;">-->
        <!--            &lt;!&ndash;          <SuggestParking v-model="query.parkingLotId" />&ndash;&gt;-->
        <!--            <el-input-->
        <!--                v-model="query.parkingName"-->
        <!--                placeholder="请输入车场名称查询"-->
        <!--                prefix-icon="el-icon-search"-->
        <!--                style="width: 254px; margin-right: 10px;"-->
        <!--            />-->
        <!--          </el-form-item>-->
        <!--          <el-form-item style="margin-bottom: 0;">-->
        <!--            <el-button type="primary" @click="goSearch">搜索</el-button>-->
        <!--            <el-button @click="resetForm">重置</el-button>-->
        <!--          </el-form-item>-->
        <!--        </el-form>-->
        <!--      </div>-->
        <div class="search-box page-header height80 padding10-38">
          <div class="content" style="margin-left: 0;">
            <img src="@/assets/images/monitor/bwzs.png" class="width29 height29">
            <div class="content-item">
              <div class="title">泊位总数</div>
              <div class="text">{{ statisticsData.spaceTotalCount }}</div>
            </div>
          </div>
          <div class="content">
            <img src="@/assets/images/monitor/zybw2.png" class="width29 height29">
            <div class="content-item">
              <div class="title">占用泊位</div>
              <div class="text">{{ statisticsData.spaceUseCount }}</div>
            </div>
          </div>
          <div class="content">
            <img src="@/assets/images/monitor/kxbw.png" class="width29 height29">
            <div class="content-item">
              <div class="title">空闲泊位</div>
              <div class="text">{{ statisticsData.spaceEmptyCount }}</div>
            </div>
          </div>
          <div class="content">
            <img src="@/assets/images/monitor/zzl.png" class="width29 height29">
            <div class="content-item">
              <div class="title">周转率</div>
              <div class="text">{{ statisticsData.parkingRate }}</div>
            </div>
          </div>
        </div>
        <div class="section search-box padding-left30 padding-right30" style="margin-top: 0;">
          <div class="font-size16" style="font-weight: bold;">泊位局视图</div>
          <div v-if="pagination.totalSize == 0" class="search-box-none">
            <img src="@/assets/images/operate_new.png" alt="">
            <p>暂无车辆</p>
          </div>
          <div class="page-content" style="border-top:0 white solid;margin-top: 0;" v-if="pagination.totalSize != 0">
            <div v-for="(item, index) in spaceStatusList" :key="index" class="car-item">
              <div class="parking-item-head">
                <span v-if="parkingLotType != 0">{{ (item.parkingSpaceCode && item.parkingSpaceCode.length > 3) ?
                  item.parkingSpaceCode.substring(item.parkingSpaceCode.length - 3) : item.parkingSpaceCode }}泊位 </span>
                <span v-else>路外泊位</span>
                <div class="parking-item-type" v-if="item.type && item.type != 0">{{ deviceList[item.type] }}</div>
                <div class="parking-item-type" @click="handlePhone(item.parkingSpaceId)" v-if="item.type == 0">视频桩</div>
              </div>
              <div style="position: relative;">
                <div @click="showPhoto(item)">
                  <Numplate :type="item.plateColor ? item.plateColor : '02'" class="margin-top10" >
                  <div class="margin1030">
                    <span class="font-size16" style="display: inline-block;">
                      {{ item.plateNumber == '' ? '泊位空闲' : item.plateNumber }}
                    </span>
                    <img v-if="item.plateNumber !== ''" src="@/assets/images/car_icon.png" style="cursor: pointer;"
                      class="width12 height12 margin-left6">
                  </div>
                </Numplate>
                </div>
                
                <div class="parkint-item-type" style="background-color: #0768FD;" v-if="item.isWhite == 1">长租
                </div>
                <div class="parkint-item-type" style="background-color: #F4933C;" v-if="item.isLongRent == 1">白名单
                </div>
              </div>

              <div class="drivein-time">
                驶入时间：{{ (item.driveInTime == null || item.driveInTime == '') ? '--:--:--' : item.driveInTime }}
                <!--              驶入时间：<span>{{ item.intoDateTime }}</span>-->
              </div>
              <div class="parking-item-content margin-top20" style="width: 100%;">
                <div style="display: flex;justify-content: space-between;">
                  <div class="margin-left20" style="display: flex;align-items: center;">
                    <div class="width6 height6 border-radius6" style="background: #C5D1CB;"></div>
                    <div class="font-size14 margin-left4" style="color: #5F666C;" v-if="!item.parkingTime">累计时长</div>
                    <div class="margin-left12 width40 height14">
                      <div class="font-size14" style="color: black;font-weight: bold;text-align: left;">{{
                        item.parkingTime||'--' }}</div>
                    </div>
                  </div>
                  <div class="margin-right20" style="display: flex;align-items: center;">
                    <div class="width6 height6 border-radius6" style="background: #C5D1CB;"></div>
                    <div class="margin-left4 font-size14" style="color: #5F666C;">订单金额</div>
                    <div class="margin-left12 width40 height14">
                      <div class="font-size14" style="color: black;font-weight: bold;text-align: left;">{{
                        item.amount||'--' }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="margin20" style="width: 100%;display: flex;justify-content: space-evenly;padding: 0 15px;">
                <!-- <div @click="go('0', item.parkingSpaceCode, parkingId, item.parkingSpaceId, )" class="padding8-20" style="background: #ECF3FF;color: #0768FD;">泊位停车历史</div>
                <div @click="go('1', item.plateNumber, item.vehicleId, item.plateColor)" class="padding8-20 margin-left25" style="background: #E5F3FF;color: #3075FA;">车辆停车历史</div> -->
                <!-- <div class="card-button" :disabled="parkingLotType == 0" @click="go('0', item.parkingSpaceCode, parkingId, item.parkingSpaceId,)">
                  泊位停车历史</div> -->
                  <el-button class="card-button" :disabled="parkingLotType == 0" @click="go('0', item.parkingSpaceCode, parkingId, item.parkingSpaceId,)">泊位停车历史</el-button>
                <div class="card-button" @click="go('1', item.plateNumber, item.vehicleId, item.plateColor)">车辆停车历史
                </div>
                <!-- <div class="card-button" @click="handlePhone(item.id)">抓拍</div> -->
              </div>
            </div>
          </div>
          <div class="pagination-box" v-if="pagination.totalSize != 0">
            <el-pagination :current-page="pagination.pageNo" :page-size="pagination.pageSize"
              :total="pagination.totalSize" background layout="sizes, prev, pager, next, jumper"
              @size-change="handleSizeChange" @current-change="handleCurrentChange">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <FullScreenDialog ref="showPsMonitorHistory" class="top15">
      <ParkingSpaceHistory :go-back="hideDialog" :history-type="historyType" :historyParkingLotId="historyParkingLotId"
        :historyParkingSpaceCode="historyParkingSpaceCode" :historyParkingSpaceId="historyParkingSpaceId"
        :historyVehicleId="historyVehicleId" :historyPlateNumber="historyPlateNumber"
        :historyPlateColor="historyPlateColor" />
      <!--      :parkingId="currentRow.id"-->
      <!--      :parkingName="currentRow.name"-->
    </FullScreenDialog>

    <el-dialog :append-to-body="true" :before-close="qrCodeDialogBeforeClosed" :visible.sync="showPhone"
      class="common-phone" title="抓拍" width="1000px">
      <div class="common-box" style="width: 100%;">
        <el-image class="back-img" :preview-src-list="[phoneUrl]" :src="phoneUrl" alt="" />
        <!-- <img class="back-img" src="@/assets/images/illustration.png" alt=""> -->
        <div class="common-posi  flx">
          <img src="@/assets/images/pict-action.png" alt="">
          <p class="common-posi-title" @click="handleGetPhone">抓拍</p>
        </div>
      </div>
    </el-dialog>
    <!-- 查看图片弹窗 -->
    <el-dialog :append-to-body="true" :visible.sync="pictureDialogFormVisible" class="common-dialog" title="停车图片">
      <div class="showPicture">
        <div class="pictureBox boxF1">
          <el-carousel class="photo-width" height="300px" trigger="click">
            <el-carousel-item v-for="(list, index) in inImgDataList" :key="index" class="photo-item">
              <el-image v-for="(item, index2) in list" :key="index2" :preview-src-list="driveInPhotoList" :src="item"
                class="top-img"></el-image>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <div class="table-list">
        <el-descriptions :column="2" border>
          <el-descriptions-item>
            <template slot="label"> 车牌号</template>
            <span v-if="orderDetatil.plateNumber != ''">
              <Numplate :type="orderDetatil.plateColor ? orderDetatil.plateColor : '02'">
                {{ orderDetatil.plateNumber }}
              </Numplate>
            </span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 停车时长</template>
            {{ orderInfoPlus.parkingTime }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 订单金额</template>
            <span>{{
              (parseFloat(orderInfoPlus.receivableAmount)).toFixed(2)
            }}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 实收（元）</template>
            <span>{{
              (parseFloat(orderInfoPlus.transactionAmount)).toFixed(2)
            }}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 是否付款</template>
            <span>
              <span v-if="orderInfoPlus.isWhite === 1">无需付款</span>
              <span v-else>{{ orderInfoPlus.isArrearage == 1 ? "已付款" : "未付款" }}</span>
            </span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 城区</template>
            {{ orderInfoPlus.regionName }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 车场</template>
            {{ orderInfoPlus.parkingLotName }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 泊位号</template>
            {{ orderInfoPlus.parkingSpaceCode }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 驶入时间</template>
            {{ orderInfoPlus.driveInTime }}
          </el-descriptions-item>
        </el-descriptions>
      </div>
      <div class="parkingDetail"></div>
    </el-dialog>
  </div>

</template>

<script>
import BaseMixin from "@/mixins/base";
import Numplate from "@/components/Numplate";
import {
  searchParkingSpaceList,
  getParkingSpaceTotal,
} from "@/api/monitor";
import ParkingSpaceHistory from "@/pages/monitor/parkingspace_history.vue";
import FullScreenDialog from "@/components/FullScreenDialog.vue";
import { searchParkingOrderDetail, getPhotoGraph } from "@/api/parkingOrder";
import { Message } from "element-ui";


export default {
  name: "monitor-parkingspace_detail",
  mixins: [BaseMixin],
  components: {
    FullScreenDialog,
    ParkingSpaceHistory,
    Numplate,
  },
  props: {
    parkingId: String,
    parkingName: String,
    parkingLotType:Number,
    goBack: null,
  },
  data() {
    return {
      pictureDialogFormVisible: false,
      inImgDataList: [], //路内停车驶入图片
      driveInPhotoList: [],
      orderDetatil: {}, //订单信息
      orderInfoPlus: {},// 订单详情补充信息
      historyType: 0,
      historyParkingLotId: '',
      historyParkingSpaceCode: '',
      historyParkingSpaceId: '',
      historyVehicleId: '',
      historyPlateNumber: '',
      historyPlateColor: '',
      showDialog: false,
      isLoading: false,
      spaceStatusList: [],
      statisticsData: {},
      query: {
        spaceCode: "",
        spaceStatus: "",
        autoType: "",
      },
      showPhone: false,
      phoneUrl: "",
      id: "",
      disablePhone: true,
      deviceList:{
        "0":"视频桩",
        "1":"地磁",
        "2":"巡检车",
        "3":"PDA",
        "4":"高点",
        "5":"相机",
      },
    };
  },
  methods: {
    go2Back() {
      if (this.goBack) {
        this.goBack();
      }
    },
    showPhoto(item) {
      if (item.plateNumber) {
        this.orderDetatil = item
        searchParkingOrderDetail(item.parkingOrderIntoId).then(res => {
          this.pictureDialogFormVisible = true
          if (res && res.code === 30 && res.result) {
            this.orderInfoPlus = res.returnObject
            this.inImgDataList = res.returnObject.imageList
            this.driveInPhotoList = res.returnObject.imageList.map(i => i.url)
          }
        });
      }
    },
    hideDialog() {
      this.showDialog = false;
      this.$refs.showPsMonitorHistory.hide();
    },
    qrCodeDialogBeforeClosed() {
      this.showPhone = false;
    },
    go(type, tag, val, val2) {
      console.log(type, tag, val, val2)
      this.historyType = type
      if ((type === '1' && tag === '') || (type !== '0' && type !== '1')) {
        this.$message.warning('该泊位为空闲泊位，无车辆信息。')
        return false
      }
      if (type === 0) {
        this.historyParkingLotId = val
        this.historyParkingSpaceCode = tag
        this.historyParkingSpaceId = val2
      } else if (type === 1) {
        this.historyVehicleId = val
        this.historyPlateNumber = tag
        this.historyPlateColor = val2
      }
      window.localStorage.setItem('history_params', JSON.stringify({
        historyType: type,
        historyParkingLotId: val,
        historyParkingSpaceCode: tag,
        historyParkingSpaceId: val2,
        historyVehicleId: val,
        historyPlateNumber: tag,
        historyPlateColor: val2,
      }))

      this.showDialog = true;
      this.$refs.showPsMonitorHistory.show();
    },
    handlePhone(id) {
      this.id = id;
      this.handleGetPhone();
    },
    // 抓拍
    handleGetPhone() {
      // 抓拍接口延迟
      if (this.disablePhone) {
        this.disablePhone = false;
        getPhotoGraph({ parkingLotGateId: this.id }).then(res => {
          // Message.info("");
          if (res && res.code == 30) {
            Message.success("抓拍成功");
            this.phoneUrl = res.returnObject;
            this.showPhone = true;
            this.disablePhone = true;
          }
        })
      } else {
        Message.info("请等待");
      }

    },
    getMore() {
      this.pagination.pageNo = 1;
      this.pagination.pageSize = 10;
      this.search();
    },
    async search() {
      this.isLoading = true;
      var res = await searchParkingSpaceList({
        parkingLotId: this.parkingId,
        // spaceCode: this.query.spaceCode,
        // spaceStatus: this.query.spaceStatus,
        // autoType: this.query.autoType,
        pageNo: this.pagination.pageNo,
        pageSize: this.pagination.pageSize
      });
      this.isLoading = false;
      if (res.code === 30) {
        this.spaceStatusList = res.returnObject.records
        this.pagination.totalSize = res.returnObject.total;
        this.pagination.totalPages = res.returnObject.pages;
        // this.pagination.pageNo = res.returnObject.current;
      }
      res = await getParkingSpaceTotal({ parkingLotId: this.parkingId })
      if (res.code === 30) {
        this.statisticsData = res.returnObject
      }
      console.log(res);
    },
    goSearch() {
      this.query.pageNo = 0
      this.search()
    },
    resetForm() {
    },
  },
};
</script>

<style lang="less" scoped>
.page-header {
  height: 90px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  .content {
    display: flex;
    align-items: center;
    margin-left: 80px;

    .content-item {
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: center;
      margin-left: 12px;


      .title {
        color: #5F666C;
        font-size: 14px;
      }

      .text {
        color: #000000;
        font-size: 20px;
        margin-top: 6px;
        font-weight: bold;
      }
    }
  }
}

.board {
  display: flex;
  flex-direction: row;
  background: #EFF8FF;
  border-radius: 10px;
  padding: 24px;

  .board-item {
    flex: 1;
    text-align: center;
    border-right: 1px solid #e6e6e6;

    &:last-child {
      border-right-width: 0;
    }

    .item-label {
      font-size: 16px;
      margin-bottom: 6px;
    }

    .item-value {
      font-size: 20px;
    }
  }
}

.search-box-none{
  width: 300px;
  margin: 20px auto;
  padding-bottom: 20px;
  img{
    width: 100%;
  }
  p{
    text-align: center;
    font-size: 25px;
  }
}

.photo-width {
  margin: 12px 8px;

  .photo-item {
    //width: 100%;
    //display: flex;

    .top-img {
      //width: 284px;
      //height: 184px;
      //margin-right: 20px;
      cursor: pointer;
    }
  }
}

.page-content {
  border-top: 1px dashed #e6e6e6;
  margin-top: 14px;
  display: flex;
  flex-wrap: wrap;
  min-width: 1200px;
  padding-top: 20px;

  .car-item {
    width: calc(20% - 20px);
    //border: 1px solid #e6e6e6;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.08);
    //border-radius: 4px;
    min-height: 180px;
    margin-bottom: 20px;
    margin-right: 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    cursor: pointer;

    &:nth-child(5n) {
      margin-right: 0;
    }

    .parking-item-head {
      height: 40px;
      width: 90%;
      //border-bottom: 1px solid #e6e6e6;
      background: #F2FAF6;
      font-size: 16px;
      //position: absolute;
      margin-left: 30px;
      font-weight: bold;
      padding-right: 50px;
      //top: 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      color: #000000;
      padding-left: 20px;

      img {
        margin: 0 4px 0 2px;
      }
    }
    .parking-item-type{
      color: gray;
    }

    .drivein-time {
      //position: absolute;
      //left: 14px;
      //right: 14px;
      //bottom: 14px;
      //background: #EFF8FF;
      margin-top: 10px;
      padding-bottom: 15px;
      border-bottom: 1px #5F666C dashed;
      color: #000000;
      font-size: 14px;
      height: 30px;
      line-height: 30px;
      text-align: center;
    }
  }

  .parkint-item-type {
    position: absolute;
    border-radius: 30px;
    border: 2px solid white;
    // padding: 6px 14px;
    font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB;
    font-weight: 400;
    font-size: 10px;
    color: #FFFFFF;

    width: 52px;
    height: 22px;
    line-height: 22px;
    text-align: center;
    top: 0px;
    right: -26px;
  }
}

.pagination-box {
  color: #3e9df5;
  font-size: 16px;
  text-align: center;
}

.card-button {
  background: #ECF3FF;
  color: #0768FD;
  padding: 8px 10px;
  // width: 80px;
  text-align: center;
}

.flx {
  display: flex;
  align-items: center;
}

.common-box {
  height: 576px;
  position: relative;
  overflow: hidden;

  .back-img {
    width: 100%;
    height: 100%;
  }

  .common-posi {
    position: absolute;
    bottom: 32px;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;

    padding: 13px 28px;
    background: #0768FD;
    border-radius: 67px 67px 67px 67px;
    border: 3px solid #CFE2FF;

    font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB;
    font-weight: 400;
    font-size: 16px;
    color: #FFFFFF;

    img {
      width: 25px;
      height: 25px;
      margin-right: 12px;
    }
  }
}
</style>
