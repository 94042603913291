<template>
  <div class="page" style="padding-top: 0px; padding-bottom: 0px;">
    <div style="background: #FAFAFA;border-radius: 4px;display: flex;height: 51px;">
      <div style="width:110px;background: #0768FD;color: white;font-size: 16px; display: flex; align-items: center;justify-content: center;border-radius:4px 0 0 4px;cursor: pointer;"
           @click="go2Back">
        返回
      </div>
      <div style="color: black;font-size: 16px;font-weight: bold;margin-left: 30px;display: flex; align-items: center;justify-content: center;">
        <div v-if="historyType==0">
          {{ historyParkingSpaceCode }}
        </div>
        <div v-else>
          <Numplate :type="historyPlateColor">
            <div style="margin: 10px 30px;">
                <span style="display: inline-block; font-size: 16px;">
                  {{ historyPlateNumber.length>2?historyPlateNumber.substr(0,2)+'·'+historyPlateNumber.substr(2):historyPlateNumber }}
                </span>
            </div>
          </Numplate>
        </div>
      </div>
    </div>
    <div v-loading="isLoading" class="content-box" style="padding-top: 0;background: #F4F7FC;">
      <div class="search-box page-header" style="height: 80px;padding: 10px 38px;">
        <div class="content" style="margin-left: 0;">
          <img src="@/assets/images/monitor/bwzs.png" style="width: 29px;height: 29px">
          <div class="content-item">
            <div class="title">停车累计时长</div>
            <div class="text">{{ parkingSpaceData.takeTime }}</div>
          </div>
        </div>
        <div class="content">
          <img src="@/assets/images/monitor/zybw2.png" style="width: 29px;height: 29px">
          <div class="content-item">
            <div class="title">停车次数</div>
            <div class="text">{{ parkingSpaceData.parkingTotalCount }}</div>
          </div>
        </div>
        <div class="content">
          <img src="@/assets/images/monitor/kxbw.png" style="width: 29px;height: 29px">
          <div class="content-item">
            <div class="title">缴费金额</div>
            <div class="text">{{ parkingSpaceData.payMoney }}</div>
          </div>
        </div>
        <div class="content">
          <img src="@/assets/images/monitor/zzl.png" style="width: 29px;height: 29px">
          <div class="content-item">
            <div class="title">欠费金额</div>
            <div class="text">{{ parkingSpaceData.unpayMoney }}</div>
          </div>
        </div>
      </div>
      <div class="section search-box" style="margin-top: 0;padding-left: 30px;padding-right: 30px;">
        <el-table
            :data="historyList"
            border
            class="common-table"
            size="medium"
            style="width: 100%"
        >
          <el-table-column label="车场名称" prop="parkingLotName" width="300"></el-table-column>
          <el-table-column label="泊位号" prop="parkingSpaceCode">
          </el-table-column>
          <el-table-column label="车牌号" prop="plateNumber" width="200">
            <template slot-scope="scope">
              <Numplate :type="scope.row.color" style="margin: 0px;padding: 0px;">
                <div style="margin: 0px 0px;width: 100px;display: flex;justify-content: center;">
                  <span style="display: inline-block; font-size: 16px;">
                    {{ scope.row.plateNumber.length>2?scope.row.plateNumber.substr(0,2)+'·'+scope.row.plateNumber.substr(2):scope.row.plateNumber }}
                  </span>
                </div>
              </Numplate>
            </template>
          </el-table-column>
          <el-table-column label="订单状态" prop="status" width="100">
            <template slot-scope="scope">
              {{ scope.row.status===0?'正常':'' }}
            </template>
          </el-table-column>
          <el-table-column label="驶入时间" width="160px" prop="driveInTime"></el-table-column>
          <el-table-column label="驶出时间" width="160px" prop="driveOutTime"></el-table-column>
          <el-table-column label="停车时长" width="120px" prop="takeTime">
          </el-table-column>
          <el-table-column label="订单金额" prop="receivableAmount" width="120"
          >
            <template slot-scope="scope">
              {{ parseFloat(scope.row.receivableAmount).toFixed(2) }}
            </template>
          </el-table-column>
          <el-table-column label="已付款" prop="transactionAmount" width="120"
          >
            <template slot-scope="scope">
              {{ parseFloat(scope.row.transactionAmount).toFixed(2) }}
            </template>
          </el-table-column>
          <el-table-column label="订单编号" width="270" prop="parkingOrderIntoId">
          </el-table-column>
          <el-table-column label="缴费状态" prop="isArrearage">
            <template slot-scope="scope">
              <span>{{ payStatus[scope.row.isArrearage] }}</span>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination-box">
          <el-pagination
              :current-page="pagination.pageNo"
              :page-size="pagination.pageSize"
              :total="pagination.totalSize"
              background
              layout="sizes, prev, pager, next, jumper"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseMixin from "@/mixins/base";
import Numplate from "@/components/Numplate";
import {
  searchParkingVehicleTotal,
  searchParkingVehiclePage,
  searchParkingOrderBySpaceTotal,
  searchParkingOrderBySpace,
} from "@/api/monitor";

export default {
  name: "monitor-parkingspace_history",
  mixins: [BaseMixin],
  components: {
    Numplate,
  },
  props: {
    goBack: null,
  },
  data() {
    return {
      historyType: '',
      historyParkingLotId: '',
      historyParkingSpaceCode:  '',
      historyParkingSpaceId: '',
      historyVehicleId: '',
      historyPlateNumber: '',
      historyPlateColor: '',
      isLoading: false,
      historyList: [],
      parkingSpaceData: {},
      payStatus: {
        '0': "欠费",
        '1': "不欠费",
        '2': "其他",
      },
    };
  },
  methods: {
    go2Back() {
      if (this.goBack) {
        this.goBack();
      }
    },
    getMore() {
      this.pagination.pageNo = 1;
      this.pagination.pageSize = 10;
      this.search();
    },
    async search() {
      var params = JSON.parse(window.localStorage.getItem('history_params'))
      this.historyType = params.historyType
      this.historyParkingLotId = params.historyParkingLotId
      this.historyParkingSpaceCode = params.historyParkingSpaceCode
      this.historyParkingSpaceId = params.historyParkingSpaceId
      this.historyVehicleId = params.historyVehicleId
      this.historyPlateNumber = params.historyPlateNumber
      this.historyPlateColor = params.historyPlateColor

      this.isLoading = true;
      if(this.historyType==='0'){
        console.log(
            this.historyParkingLotId,
            this.historyParkingSpaceCode,
            this.historyParkingSpaceId,
            this.historyVehicleId,
            this.historyPlateNumber,
            this.historyPlateColor,
      )
        searchParkingOrderBySpace({
          parkingLotId: this.historyParkingLotId,
          parkingSpaceId: this.historyParkingSpaceId,
          pageNo: this.pagination.pageNo,
          pageSize: this.pagination.pageSize
        }).then(res => {
          this.isLoading = false;
          if (res.code === 30) {
            this.historyList = res.returnObject.records
            this.pagination.totalSize = res.returnObject.total;
            this.pagination.totalPages = res.returnObject.pages;
          }
        });
        searchParkingOrderBySpaceTotal({
          parkingLotId: this.historyParkingLotId,
          parkingSpaceId: this.historyParkingSpaceId,
        }).then(res => {
          this.isLoading = false;
          if (res.code === 30) {
            this.parkingSpaceData = res.returnObject
          }
        });
      }else if(this.historyType==='1'){
        searchParkingVehiclePage({
          vehicleId: this.historyVehicleId,
          pageNo: this.pagination.pageNo,
          pageSize: this.pagination.pageSize
        }).then(res => {
          this.isLoading = false;
          if (res.code === 30) {
            this.historyList = res.returnObject.records
            this.pagination.totalSize = res.returnObject.total;
            this.pagination.totalPages = res.returnObject.pages;
          }
        });
        searchParkingVehicleTotal({
          vehicleId: this.historyVehicleId,
        }).then(res => {
          this.isLoading = false;
          if (res.code === 30) {
            this.parkingSpaceData = res.returnObject
          }
        });
      }
    },
    goSearch() {
      this.query.pageNo = 0
      this.search()
    },
    resetForm() {
    },
  },
};
</script>

<style lang="less" scoped>
.page-header {
  height: 90px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  .content {
    display: flex;
    align-items: center;
    margin-left: 80px;

    .content-item {
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: center;
      margin-left: 12px;


      .title {
        color: #5F666C;
        font-size: 14px;
      }

      .text {
        color: #000000;
        font-size: 20px;
        margin-top: 6px;
        font-weight: bold;
      }
    }
  }
}

.board {
  display: flex;
  flex-direction: row;
  background: #EFF8FF;
  border-radius: 10px;
  padding: 24px;

  .board-item {
    flex: 1;
    text-align: center;
    border-right: 1px solid #e6e6e6;

    &:last-child {
      border-right-width: 0;
    }

    .item-label {
      font-size: 16px;
      margin-bottom: 6px;
    }

    .item-value {
      font-size: 20px;
    }
  }
}

.page-content {
  border-top: 1px dashed #e6e6e6;
  margin-top: 14px;
  display: flex;
  flex-wrap: wrap;
  min-width: 1200px;
  padding-top: 20px;

  .car-item {
    width: calc(20% - 20px);
    //border: 1px solid #e6e6e6;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.08);
    //border-radius: 4px;
    min-height: 180px;
    margin-bottom: 20px;
    margin-right: 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    cursor: pointer;

    &:nth-child(5n) {
      margin-right: 0;
    }

    .parking-item-head {
      height: 40px;
      width: 100%;
      //border-bottom: 1px solid #e6e6e6;
      background: #F2FAF6;
      font-size: 16px;
      //position: absolute;
      margin-left: 20px;
      font-weight: bold;
      //top: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      color: #000000;
      padding-left: 20px;

      img {
        margin: 0 4px 0 2px;
      }
    }

    .drivein-time {
      //position: absolute;
      //left: 14px;
      //right: 14px;
      //bottom: 14px;
      //background: #EFF8FF;
      margin-top: 10px;
      padding-bottom: 15px;
      border-bottom: 1px #5F666C dashed;
      color: #000000;
      font-size: 14px;
      height: 30px;
      line-height: 30px;
      text-align: center;
    }
  }
}

.pagination-box {
  color: #3e9df5;
  font-size: 16px;
  text-align: center;
}
</style>
